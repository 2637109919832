<template>
  <div>
    <div class="mt-2">
      <data-iterator :headers="payloadHeaders" :payload="payload" :key="reInit"></data-iterator>
    </div>
    <v-dialog v-model="drawer" absolute bottom temporary right width="500px">
      <v-card elevation="0" height="100%">
        <v-card-title class="primary white--text px-4">
          <span class="title"> {{ modelObj._id ? 'Update Enquiry' : 'Add Enquiry'}} </span>
        </v-card-title>
        <v-form ref="form">
          <v-card-text class="pt-0">
            <v-row class="mx-4">
              <v-col cols="12">
                <v-radio-group v-model="modelObj.enquiry" hide-details row>
                  <v-radio label="Visitor" value="visitor"></v-radio>
                  <v-radio label="User" value="user"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense hide-details label="Name" v-model="modelObj.name" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense hide-details label="Email" v-model="modelObj.email" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense hide-details label="Phone Number" v-model="modelObj.phone"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense hide-details label="Id Card type" v-model="modelObj.proof_type"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense hide-details label="Id Card number" v-model="modelObj.proof_number"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense hide-details label="Address" v-model="modelObj.address"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-layout class="d-flex justify-center">
            <v-btn color="success" class="mx-2" @click="saveUpdateHandler()">{{modelObj._id ? 'Update' : 'Save'}}</v-btn>
            <v-btn @click="drawer= false" color="error">Cancel</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  data () {
    return {
      drawer: false,
      routesDrawer: false,
      reInit: 0,
      listOfForms: [],
      listOfUsers: [],
      modelObj: {},
      payload: {
        // showBackBtn: false,
        create: () => this.drawer = true,
        items: [],
        showSelect: false,
        actionsList: [
          {
            click: (item) => this.editHandler(item._id),
            icon: 'mdi-pencil',
            color: 'primary'
          },
          {
            click: (item) => this.deleteHandler(item._id),
            icon: 'mdi-delete',
            color: 'error'
          }
        ]
      }
    }
  },
  mounted () {
    this.getAllUsers()
    this.getListHandler()
  },
  computed: {
    payloadHeaders () {
      return [{
        text: 'User',
        value: 'name'
      }, {
        text: 'Enquiry To',
        value: 'enquiry_to'
      }, {
        text: 'Enquiry Date',
        value: 'enquiry_date'
      }, {
        text: 'Status',
        value: 'status'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  methods: {
    getAllUsers () {
      this.$_execute('get', 'users').then(({ data }) => {
        this.listOfUsers = data
      })
    },
    getListHandler () {
      this.$_execute('get', 'enquiry').then(response => {
        this.payload.items = response.data
        this.reInit++
      })
    },
    saveUpdateHandler () {
      let url = this.modelObj._id ? `enquiry/${this.modelObj._id}` : `/enquiry`
      let method = this.modelObj._id ? `put` : `post`
      this.$_execute(method, url, this.modelObj).then(() => {
        this.$root.$emit('snackbar', { message: this.modelObj._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
        this.$refs.form.reset()
        this.modelObj = {}
        this.drawer = false
        this.getListHandler()
      })
    },
    editHandler (id) {
      this.$_execute('get', `enquiry/${id}`).then(response => {
        this.drawer = true
        let model = response.data[0]
        this.modelObj = model
      })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'enquiry',
        _id: id,
        onResolve: () => {
          this.dialog = false
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    }
  }
}
</script>
